const getRoute = (args: {
  endpont: string;
  id?: string | number | undefined;
  rest?: string | number | undefined;
}) => {
  const apiMap: { [key: string]: string } = {
    post_signup: "/api/auth/native/sign-up",
    post_signup_confirm: "/api/auth/native/sign-up/confirm",
    post_signup_resend: "/api/auth/native/sign-up/resend",
    get_user: "/api/users/me",
    post_refresh_token: "/api/auth/token/refresh",
    get_google_auth: "/api/auth/google",
    get_linkedin_auth: "/api/auth/linkedin",
    post_recovery_send_mail: "/api/auth/native/password-recovery",
    post_recovery_confirm: "/api/auth/native/password-recovery/confirm",
    post_signin: "/api/auth/native/sign-in",
    get_profiles: "/api/profiles",
    get_profile_by_id: `/api/profiles/${args.id}`,
    post_create_profile: "/api/profiles",
    put_update_profile: `/api/profiles/${args.id}`,
    post_files: "/api/files",
    get_company: `/api/company/${args.id}`,
  };
  return apiMap[args.endpont];
};

export default getRoute;
