import { serializeObject } from "~/utilities/helpers/query/serializeObject";

interface ApiFunctionArgs {
  url: string;
  query?: Record<string, any>;
  body?: Record<string, any>;
  headers?: Record<string, any>;
  imageFile?: string | Blob | undefined;
}

export const apiPost = async ({
  url,
  query,
  body,
  headers,
}: ApiFunctionArgs) => {
  const config = useRuntimeConfig();
  const baseUrl = config.public.baseURL as string;
  let errors = null;
  let status: number | undefined = undefined;
  let queryStrings: string[] = [];
  const setUrl = baseUrl + url;
  if (query) {
    queryStrings = Object.entries(query).map(([key, value]) => {
      if (Array.isArray(value)) {
        return value
          .map((item) => `${key}[]=${encodeURIComponent(item)}`)
          .join("&");
      } else {
        return `${key}=${encodeURIComponent(value)}`;
      }
    });
  }
  const queryString = queryStrings ? queryStrings.join("&") : "";
  try {
    const { data } = await useFetch(
      setUrl + (queryString ? `?${queryString}` : ""),
      {
        method: "POST",
        headers: {
          ...headers,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
        onResponse({ response, options }) {
          status = response.status;
        },
        onResponseError({ response }) {
          errors = response._data;
          status = response.status;
        },
      }
    );

    return { data, errors, status };
  } catch (error) {}
};

export const apiGet = async ({ url, query, headers }: ApiFunctionArgs) => {
  try {
    const config = useRuntimeConfig();
    const baseUrl = config.public.baseURL as string;
    const setUrl = baseUrl + url;
    let queryString = "";
    if (query) {
      queryString = serializeObject(query);
    }

    const { data, error, status } = await useFetch(
      setUrl + (queryString ? `?${queryString}` : ""),
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          ...headers,
        },
        //delete before deployment
        // mode: "no-cors",
        onResponse({ response, options }) {},
        onResponseError({ response }) {},
      }
    );

    return { data, error, status };
  } catch (error: any) {
    console.error(error);
  }
};

export const apiPut = async ({
  url,
  query,
  body,
  headers,
}: ApiFunctionArgs) => {
  const config = useRuntimeConfig();
  const baseUrl = config.public.baseURL as string;
  let errors = null;
  let status: number | undefined = undefined;
  let queryStrings: string[] = [];
  const setUrl = baseUrl + url;
  if (query) {
    queryStrings = Object.entries(query).map(([key, value]) => {
      if (Array.isArray(value)) {
        return value
          .map((item) => `${key}[]=${encodeURIComponent(item)}`)
          .join("&");
      } else {
        return `${key}=${encodeURIComponent(value)}`;
      }
    });
  }
  const queryString = queryStrings ? queryStrings.join("&") : "";
  try {
    const { data } = await useFetch(
      setUrl + (queryString ? `?${queryString}` : ""),
      {
        method: "PUT",
        headers: {
          ...headers,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
        onResponse({ response, options }) {
          status = response.status;
        },
        onResponseError({ response }) {
          errors = response._data;
          status = response.status;
        },
      }
    );
    return { data, errors, status };
  } catch (error) {}
};